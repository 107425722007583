import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from './logo512.png';
import './App.css';
import Pricing from './Pricing'; // Import the Pricing component
import Success from './Success'; // Import the Success component
import Error from './Error'; // Import the Error component
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CreditsContext } from "./CreditsContext"; // Import the context

const PrivacyDisclaimer = () => (
  <div className="App">
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full mx-auto">
      <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">Privacy Disclaimer</h1>
      <p className="text-gray-700">
        Your BlueSky credentials (username and password) are used directly from your device
        to make API calls to BlueSky. These credentials are never stored or shared with
        any external services.
      </p>
      <a href="/bluesky" className="text-blue-500 hover:underline mt-4 block text-center">Back to App</a>
    </div>
  </div>
);

const MainPage = ({ credits, setCredits }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    console.log("Updated Credits:", credits);
  }, [credits]);

  const handleRedirect = () => {
    if (credits <= 0) {
      navigate("/pricing"); // React Router navigation
    } else {
      handleSubmit(); // Call the submission handler
    }
  };

  const handleSubmit = async () => {
    if (!username || !password || !prompt) {
      setError("All fields are required.");
      setResponse("");
      return;
    }

    try {
      const res = await fetch("https://bsky-post-server-509ef1a1b88c.herokuapp.com/api/post", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
          prompt,
        }),
      });

      if (res.ok) {
        const data = await res.json();
        setResponse(data.message);
        setError("");

        if (credits > 0) {
          const newCredits = credits - 1;
          setCredits(newCredits);
          localStorage.setItem("blueskyCredits", newCredits);
        }
      } else {
        const errorData = await res.json();
        setError(errorData.error || "Failed to fetch data from the server.");
        setResponse("");
      }
    } catch (err) {
      setError("An error occurred: " + err.message);
      setResponse("");
    }
  };

  return (
    <div className="App">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full mx-auto">
        <img src={logo} alt="BlueSky Logo" className="Static-logo mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">BlueSky A.I. Post App</h1>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your BlueSky username"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your BlueSky password"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">A.I. Prompt:</label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter your A.I. post prompt"
          ></textarea>
        </div>
        {response && <p className="text-green-600 mt-4 text-center">{response}</p>}
        {error && <p className="text-red-600 mt-4 text-center">{error}</p>}
        <p className="text-gray-700 mb-4 text-center">
          {credits > 0 ? (
            <>
              Credits available: <span className="font-bold">{credits}</span>
            </>
          ) : (
            "No credits remaining. Please view pricing plans."
          )}
        </p>

        <button
          onClick={handleRedirect}
          className="w-full bg-blue-500 text-white font-semibold py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Post to BlueSky
        </button>
        <div className="mt-4">
        <Link to="/pricing" className="block text-center">
        <button className="bg-green-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-green-600 transition">
          View Pricing Plans
        </button>
        </Link>
        <Link to="/privacy" className="text-blue-500 hover:underline mt-4 block text-center">
        Privacy Disclaimer
        </Link>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const [credits, setCredits] = useState(() => {
    const savedCredits = parseInt(localStorage.getItem("blueskyCredits"), 10);
    return !isNaN(savedCredits) && savedCredits > 0 ? savedCredits : 0;
  });

  return (
    <CreditsContext.Provider value={{ credits, setCredits }}>
    <Router basename="/bluesky">
      <Routes>
        <Route path="/privacy" element={<PrivacyDisclaimer />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/success" element={<Success />} />
        <Route path="/error" element={<Error />} />
        <Route
          path="/"
          element={
            <MainPage
              credits={credits}
              setCredits={setCredits}
            />
          }
        />
      </Routes>
    </Router>
    </CreditsContext.Provider>
  );
};

export default App;
