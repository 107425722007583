// Pricing.js
import React, { useState } from 'react';
import './Pricing.css';


function Pricing() {
  const [loadingStates, setLoadingStates] = useState({
    basic: false,
    pro: false,
    enterprise: false,
  });

  // Stripe API key from environment
  const stripeApiKey = process.env.REACT_APP_STRIPE_TEST_KEY;

  const createPaymentLink = async (plan) => {
    try {
      setLoadingStates((prevState) => ({ ...prevState, [plan.name]: true }));

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${stripeApiKey}`,
      };

      // Step 1: Create the Product in Stripe
      const productData = new URLSearchParams({
        name: plan.name,
        description: plan.description,
      }).toString();

      const productResponse = await fetch('https://api.stripe.com/v1/products', {
        method: 'POST',
        headers,
        body: productData,
      });
      const product = await productResponse.json();

      if (!product.id) {
        throw new Error('Failed to create product');
      }

      // Step 2: Create the Recurring Price in Stripe
      const priceData = new URLSearchParams({
        unit_amount: plan.amount,
        currency: 'usd',
        product: product.id,
        'recurring[interval]': 'month',
      }).toString();

      const priceResponse = await fetch('https://api.stripe.com/v1/prices', {
        method: 'POST',
        headers,
        body: priceData,
      });
      const price = await priceResponse.json();

      if (!price.id) {
        throw new Error('Failed to create price');
      }

      // Step 3: Create a Checkout Link
      const linkData = new URLSearchParams({
        'line_items[0][price]': price.id,
        'line_items[0][quantity]': 1,
        mode: 'subscription',
        success_url: `https://www.jibodev.eu/bluesky/Success?plan=${plan.name}&credits=${plan.credits}`,
        cancel_url: 'https://jibodev.eu/bluesky/cancel',
      }).toString();

      const linkResponse = await fetch('https://api.stripe.com/v1/checkout/sessions', {
        method: 'POST',
        headers,
        body: linkData,
      });
      const checkoutSession = await linkResponse.json();

      if (!checkoutSession.url) {
        throw new Error('Failed to create payment link');
      }

      // Redirect to the checkout session
      window.location.href = checkoutSession.url;
    } catch (error) {
      console.error('Error creating subscription link:', error);
    } finally {
      setLoadingStates((prevState) => ({ ...prevState, [plan.name]: false }));
    }
  };

  // Plan details
  const plans = [
    {
      name: 'basic',
      description: '10 credits for posting to BlueSky',
      amount: 1000, // $10/month in cents
      credits: 10, // Credits included
    },
    {
      name: 'pro',
      description: '50 credits for posting to BlueSky',
      amount: 4000, // $40/month in cents
      credits: 50, // Credits included
    },
    {
      name: 'enterprise',
      description: 'Unlimited credits and priority support',
      amount: 10000, // $100/month in cents
      credits: Infinity, // Unlimited access
    },
  ];

  return (
    <div className="pricing-container">
      <h2>Pricing Plans</h2>

      <div className="pricing-grid">
        {plans.map((plan) => (
          <div key={plan.name} className="pricing-item">
            <h3>{plan.name.charAt(0).toUpperCase() + plan.name.slice(1)} Plan</h3>
            <p>{plan.description}</p>
            <p>${plan.amount / 100}/month</p>

            <button
              className="generate-button"
              onClick={() => createPaymentLink(plan)}
              disabled={loadingStates[plan.name]}
            >
              {loadingStates[plan.name] ? 'Redirecting...' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
